<template>
  <div>
    <Create
      :fields="fields"
      model="vehicle"
      modelName="Vehículos"
      list_url="core_vehicle_list"
      backend_url="core/vehicle"
    ></Create>
  </div>
</template>

<script>
import Create from "@/view/pages/crud/Create";

export default {
  name: "VehicleCreate",
  components: { Create },
  data() {
    return {
      fields: [
        { key: "plate", label: "Placa" },
        { key: "brand", label: "Marca" },
        { key: "vehicle_model", label: "Modelo" },
      ],
    };
  },
};
</script>

<style scoped></style>
